<template>
  <el-container direction="vertical">
    <Header @search="search_data"></Header>
    <el-main>
      <!--广告-->
      <div class="ad">
        <!--广告容器-->
        <div class="ad_container">
          <!--分栏广告-->
          <BannerAds></BannerAds>
          <!--文字广告-->
          <TextAds class="mt22"></TextAds>
        </div>
      </div>
      <!--面包屑-->
      <div class="bread_crumbs">
        <div class="bread_content">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item to="/">首页</el-breadcrumb-item>
            <el-breadcrumb-item>球员</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </div>
      <!--内容-->
      <div class="content">
        <!--过滤器-->
        <div class="filters">

          <!--生日-->
          <div class="filter" v-for="(item, index) in filter" :key="index">
            <div class="title">{{ item.label }}:</div>
            <div class="btn">
              <div class="options">
                <el-checkbox-group  size="small">
                  <el-checkbox-button label="全部"/>
                  <el-checkbox-button :label="value" v-for="(value, key) in item.item" :key="key"/>
                </el-checkbox-group>
              </div>
            </div>
          </div>
        </div>
        <!--运动员数据-->
        <div class="sportsman_data">
          <div class="item" v-for="(item, index) in sportsman_data.page_data" :key="index">
            <img :src="item.cover_info.image" alt="球员图片"  @click="jump_page('sportsman_detail', {Id: item._id}, true)">
            <div class="text">
              <span class="name" @click="jump_page('sportsman_detail', {Id: item._id}, true)">{{ item.cover_info.chinese_name }}</span>
              <p class="name" @click="jump_page('sportsman_detail', {Id: item._id}, true)">{{ item.cover_info.english_name }}</p>
              <p>出生日期：{{ item.cover_info.date_of_birth }}</p>
              <p>场上位置：{{ item.cover_info.field_position }}</p>
              <p>巅峰能力：{{ item.cover_info.peak_ability }}</p>
              <p>代表国家：{{ item.cover_info.represent_the_country }}</p>
            </div>
          </div>
          <!--分页器-->
          <div class="pager">
            <el-pagination background v-model="pages" @current-change="switch_to_page" layout="prev, pager, next" :total="sportsman_data.total_page * 10" />
          </div>
        </div>
      </div>
    </el-main>
    <Footer></Footer>
  </el-container>
</template>

<script>
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import BannerAds from "../../components/BannerAds";
import TextAds from "../../components/TextAds";
import {get_sportsman_list, get_item_dict} from "../../api/api";
import {jump_page} from "../../tool/tools";
import API_CONFIG from "../../../vue.config";

export default {
  name: "Sportsman",
  mounted() {
    this.obtain_sportsman_list();
    this.obtain_item_dict();
  },
  data(){
    return {
      pages: 1,
      sportsman_data: {
        search: {},
        current_page: 1,
        page_data: [],
        quantity: 10,
        total_page: 1
      },
      sportsman_list: [],
      filter: [
        {label: "生日", item: []},
        {label: "国籍", item: []},
        {label: "位置", item: []},
        {label: "能力", item: []},
      ]
    }
  },
  methods: {
    jump_page,
    obtain_sportsman_list(){
      let data = {
        search: {},
        page_number: this.sportsman_data.current_page
      }
      get_sportsman_list(data).then(response => {
        if(response.Code === 1){
          this.sportsman_data = response.Data;
          for(let i=0; i <= this.sportsman_data.page_data.length; i++){
            this.sportsman_data.page_data[i].cover_info.image = API_CONFIG.devServer.proxy["/api"].target + "/" + this.sportsman_data.page_data[i].cover_info.image;
          }
        }
      });
    },
    obtain_item_dict(){
      let path = window.location.pathname;
      for (let i = 0; i < this.filter.length; i++) {
        get_item_dict({
          position: this.filter[i].label,
          path: path
        }).then(response => {
          if(response.Code === 1){
            let item = [];
            for (let j = 0; j < response.Data.item.length; j++) {
              item.push(response.Data.item[j].value);
            }
            this.filter[i].item = item;
          }
        });
        console.log(this.filter[i].label);
      }
    },
    switch_to_page(page){
      this.sportsman_data.current_page = page;
      this.obtain_sportsman_list();
      console.log("---------------------");
    },
    search_data(text){
      let data = {
        search: {
          name: text
        },
        page_number: this.sportsman_data.current_page
      }
      get_sportsman_list(data).then(response => {
        if(response.Code === 1){
          this.sportsman_data = response.Data;
          for(let i=0; i <= this.sportsman_data.page_data.length; i++){
            this.sportsman_data.page_data[i].cover_info.image = API_CONFIG.devServer.proxy["/api"].target + "/" + this.sportsman_data.page_data[i].cover_info.image;
          }
        }
      });
    },
  },
  components: {
    Header,
    Footer,
    BannerAds,
    TextAds
  }
}
</script>

<style scoped>
@import "../../static/universal/index.css";

.bread_crumbs{
  height: 36px;
  background: #2e2f31;
  color: #FFFFFF;
}

.bread_crumbs > .bread_content{
  width: 1050px;
  height: 36px;
  line-height: 36px;
  margin: 0 auto;
  font-size: 18px;
}

.bread_crumbs > .bread_content > .el-breadcrumb{
  line-height: 36px;
  font-size: 16px;
  color: #FFFFFF;
}

.bread_crumbs > .bread_content > .el-breadcrumb{
  line-height: 36px;
  font-size: 16px;
}

.bread_crumbs > .bread_content > .el-breadcrumb >>> .el-breadcrumb__inner{
  color: #FFFFFF;
}

/* 过滤器 */
.filter:first-child{
  margin-top: 0;
}

.filters {
  margin-bottom: 20px;
}

.filter {
  margin-top: 15px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 10px 15px 0 10px;
}

.filter > .title{
  font-size: 15px;
  font-weight: bold;
  margin-top: 5px;
}

.filter > .btn{
  display: flex;
  align-items: flex-start;
  margin-left: 10px;
}
.filter > .btn > .options {
  width: 980px;
  height: auto;
  overflow: hidden;
}
.filter > .btn > .options > button{
  border: none;
}

.filter > .btn > .options > .button_item {
  font-size: 12px;
  background: #FFFFFF;
  color: #656565;
  padding: 0 7px;
  height: 18px;
  line-height: 18px;
  margin: 0 5px 10px 0;
  cursor: pointer;
}

.filter > .btn > .options > .button_item:hover{
  background: #cb2a32;
  color: #FFFFFF;
}

.sportsman_data{
  padding: 0 3px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.sportsman_data > .item{
  width: 304px;
  background: #f7f8fa;
  margin: 0 12px 10px 12px;
  padding: 10px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.sportsman_data > .item > img{
  width: 100px;
  height: 100px;
  margin-right: 13px;
}

.sportsman_data > .item > .text{
  flex: 1;
  width: 200px;
  font-size: 12px;
}

.sportsman_data > .item > .text > p{
  width: 100%;
  height: 17px;
  line-height: 17px;
}

.sportsman_data > .item > .text > .name{
  color: #35b260;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
}

.sportsman_data > .item > .text > .name:hover{
  color: #a11e2e;
}

.pager {
  width: 1050px;
  margin-top: 20px;
  margin-bottom: 20px;
  /*text-align: center;*/
}

.el-pagination {
  justify-content: center;
}

/*.el-pagination >>> */
.el-pagination .el-pager li{
  background-color: #00ff80;
}

::v-deep .el-pagination.is-background .el-pager li:not(.is-disabled).is-active {
  background-color: #35b260;
}

</style>